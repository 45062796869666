<template>
  <b-modal
    centered
    title="Doriți să plasați din nou această comandă?"
    ok-title="Da"
    cancel-title="Nu"
    cancel-variant="danger"
    v-on:hide="resolve($event.trigger === 'ok')"
    v-model="visible"
  >
    <OrderWidget
      v-if="order"
      timestamp="created"
      v-bind:order="order"
      v-bind:disabled="true"
    />
  </b-modal>
</template>

<script>
import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

export default {
  components: {
    OrderWidget,
  },

  data() {
    return {
      order: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    show(order) {
      this.order = order;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.order = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-footer {
        justify-content: space-between;
      }
    }
  }

  .order-address-tooltip {
    max-width: 80%;

    .tooltip-inner {
      max-width: 100%;
    }
  }
}
</style>

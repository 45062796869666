<template>
  <b-modal
    centered
    title="Transfer apel"
    ok-title="Transferă"
    cancel-title="Renunță"
    cancel-variant="danger"
    v-model="visible"
    v-on:hide="resolve($event.trigger === 'ok')"
  >
    <p v-if="dispatcher" class="font-size-h6">
      Clientul acestei comenzi discută cu dispecerul <strong>{{ dispatcher.name }}</strong>.
      Sunteți sigur că doriți să transferați apelul la dvs.?
    </p>

    <OrderWidget v-if="order" v-bind:order="order" v-bind:disabled="true" />
  </b-modal>
</template>

<script>
import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

export default {
  components: {
    OrderWidget,
  },

  data() {
    return {
      order:      null,
      dispatcher: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    show(order, dispatcher) {
      this.order      = order;
      this.dispatcher = dispatcher;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.order      = null;
      this.dispatcher = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-footer {
        justify-content: space-between;
      }
    }
  }

  .order-address-tooltip {
    max-width: 80%;

    .tooltip-inner {
      max-width: 100%;
    }
  }
}
</style>

<template>
  <b-modal
    centered
    size="lg"
    v-model="visible"
    v-bind:hide-footer="true"
    v-bind:title="`Ultimele comenzi pentru ${phone}`"
    v-on:hide="resolve(selected)"
  >
    <b-list-group>
      <b-list-group-item
        button
        v-for="order in orders"
        v-bind:key="order.id"
        v-bind:active="selected === order"
        v-on:click="select(order)"
      >
        <OrderWidget
          timestamp="created"
          v-bind:order="order"
          v-bind:disabled="true"
        />
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

export default {
  components: {
    OrderWidget,
  },

  data() {
    return {
      phone:    null,
      orders:   [],
      selected: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    select(order) {
      this.selected = order;
      this.visible  = false;
    },

    show(phone, orders) {
      this.phone    = phone;
      this.orders   = orders;
      this.selected = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.phone    = null;
      this.orders   = [];
      this.selected = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .list-group-item {
        padding: 0;
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>

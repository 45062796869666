<template>
  <div id="dispatch-map">
    <Map
      ref="map"
      v-bind:center="centerCoords"
      v-on:mounted="mapMounted"
      v-on:click="mapClicked"
      v-on:pointermove="mapPointerMoved"
    >
      <vl-feature v-if="markCoords">
        <vl-geom-point v-bind:coordinates="markCoords" />
        <vl-style-box>
          <vl-style-icon src="media/markers/house.png" v-bind:anchor="[0.5, 1]" />
        </vl-style-box>
      </vl-feature>

      <vl-feature v-if="highlightCoords">
        <vl-geom-point v-bind:coordinates="highlightCoords" />
        <vl-style-box>
          <vl-style-icon src="media/markers/place.png" v-bind:anchor="[0.5, 1]" />
        </vl-style-box>
      </vl-feature>

      <vl-feature v-for="car in cars" v-bind:key="car.id" v-bind:properties="{ car }">
        <vl-geom-point v-bind:coordinates="car.coords.pseudo" />
        <vl-style-func v-bind:factory="mapStyleCar" />
      </vl-feature>

      <vl-interaction-select v-bind:features.sync="selected" v-bind:filter="mapIsFeatureCar">
        <template slot-scope="select">
          <vl-style-box>
            <vl-style-stroke color="#423e9e" v-bind:width="7" />
            <vl-style-fill v-bind:color="[ 254, 178, 76, 0.7 ]" />
          </vl-style-box>

          <vl-overlay
            v-for="feature in select.features"
            v-bind:key="feature.id"
            v-bind:id="feature.id"
            v-bind:position="findPointOnSurface(feature.geometry)"
            v-bind:auto-pan="true"
            v-bind:auto-pan-margin="100"
            v-bind:auto-pan-animation="{ duration: 300 }"
            class="feature-popup"
          >
            <b-card no-body>
              <template v-slot:header>
                <h6 v-if="feature.properties.car.driver" class="d-inline-block mb-0">
                  {{ feature.properties.car.id }} - {{ feature.properties.car.driver.name }}
                </h6>
                <h6 v-else class="d-inline-block mb-0">
                  {{ feature.properties.car.id }}
                </h6>

                <b-button-close v-on:click="selected = []" />
              </template>

              <OrderWidget
                v-if="feature.properties.car.order"
                timestamp="confirmed"
                v-bind:order="feature.properties.car.order"
                v-bind:disabled="true"
              />
            </b-card>
          </vl-overlay>
        </template>
      </vl-interaction-select>
    </Map>
  </div>
</template>

<script>
import { fromLonLat, toLonLat } from "ol/proj";
import { Icon, Fill, Stroke, Style, Text } from "ol/style";
import { findPointOnSurface } from "vuelayers/lib/ol-ext";

import api from "@/core/services/api";

import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

import { default as Map, projections } from "@/view/content/widgets/map/MapBase.vue";

const statusColors = {
  "inOrder": "#ff0000",
};

let subscriptions;

function mapCar(car) {
  const lonLat = [car.lng, car.lat];

  const order = {
    ...car.order,

    car: {
      id: car.id,
      driver: car.driver ? car.driver.name : null,
    },
  };

  if (order.multiple) {
    order.multiple.index++;
  }

  return {
    id: car.id,

    status:  car.status,
    driver:  car.driver,
    order,
    station: car.station,

    color: statusColors[car.status],

    coords: {
      lonLat,
      pseudo: fromLonLat(lonLat, projections.pseudo),
    },
  };
}

export default {
  components: {
    Map,
    OrderWidget,
  },

  props: {
    center: {
      type: Object,
      default: null,
    },

    mark: {
      type: Object,
      default: null,
    },

    highlight: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      centerCoords: undefined,
      markCoords: undefined,
      highlightCoords: undefined,

      selected: [],

      cars: [],
    };
  },

  watch: {
    center(position) {
      if (position) {
        this.$refs.map.resetZoom();
        this.centerCoords = [position.lng, position.lat];
      }
    },

    mark(position) {
      if (position) {
        this.markCoords = fromLonLat([position.lng, position.lat], projections.pseudo);
      }
    },

    highlight(position) {
      if (position) {
        this.highlightCoords = fromLonLat([position.lng, position.lat], projections.pseudo);
      }
    },
  },

  created() {
    subscriptions = [
      api.connection.events.reconnected.subscribe(this.loadCars),

      api.cars.events.active.subscribe(this.replaceCars)
    ];
  },

  destroyed() {
    subscriptions.forEach(sub => sub.unsubscribe());
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    reset() {
      this.$refs.map.reset();

      this.markCoords      = null;
      this.highlightCoords = null;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    findPointOnSurface,

    async mapMounted() {
      const map  = document.querySelector("#dispatch-map .vl-map");
      const form = document.getElementById("order-form");

      map.style.height = form.clientHeight + "px";
      this.$refs.map.getOlMap().updateSize();

      await this.loadCars();
    },

    mapClicked(event) {
      const features = event.map.getFeaturesAtPixel(event.pixel);

      if (features && features.some(this.mapIsFeatureCar)) return;

      const lonLat = toLonLat(event.coordinate, projections.pseudo);

      this.$emit("click", {
        lat: lonLat[1],
        lng: lonLat[0],
      });
    },

    mapPointerMoved(event) {
      const element  = event.map.getTargetElement();
      const features = event.map.getFeaturesAtPixel(event.pixel);

      if (features && features.length > 0) {
        if (this.mapIsFeatureCar(features[0])) {
          element.style.cursor = "pointer";

          return;
        }
      }

      element.style.cursor = "";
    },

    mapIsFeatureCar(feature) {
      return feature.get("car");
    },

    mapStyleCar() {
    	return feature => {
        const car = feature.get("car");

        return new Style({
          image: new Icon({
            src: "media/markers/taxi.png",
            color: car.color,
          }),

          text: new Text({
            text: car.id,

            font: "600 8pt Poppins, Helvetica, 'sans-serif'",
            fill: new Fill({ color: [ 0, 0, 255 ] }),

            padding: [ 1, 2, 0, 2 ],
            offsetY: -20,

            backgroundFill: new Fill({ color: [ 255, 255, 255 ] }),
            backgroundStroke: new Stroke({ color: [ 0, 0, 255 ] }),
          }),
        });
      };
    },

    async loadCars() {
      await api.cars.register();

      const cars = await api.cars.listOnline();

      this.replaceCars(cars);
    },

    replaceCars(cars) {
      const mapped = cars.filter(car => car.lng && car.lat).map(mapCar);

      this.cars = Object.freeze(mapped);
    },
  },
};
</script>

<style lang="scss">
.vl-map {
  height: 400px;
  background: white;
  border-radius: $card-border-radius;

  .ol-viewport {
    border-radius: $card-border-radius;
  }

  .ol-zoom {
    .ol-zoom-out {
      margin-top: 13.65em;
    }
  }

  .ol-zoomslider {
    top: 2.25em;
  }

  .vl-overlay {
    .card {
      .card-header {
        padding: 1rem;

        &:only-child {
          border: 0;
        }

        button.close {
          margin-left: 1rem;
        }
      }
    }
  }
}
</style>

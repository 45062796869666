<template>
  <div>
    <b-row>
      <b-col cols="9" class="col-xxl-10">
        <b-row>
          <b-col cols="4">
            <OrderFormWidget
              ref="orderForm"
              v-on:show-all-orders="showAllOrdersChanged"
              v-on:address-changed="addressChanged"
              v-on:new-order="newOrder"
              v-on:reset="mapClear"
            />
          </b-col>

          <b-col cols="8">
            <MapWidget
              ref="map"
              v-bind:center="centeredLocation"
              v-bind:mark="markedLocation"
              v-bind:highlight="highlightedLocation"
              v-on:click="mapClicked"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <OrdersListWidget
              title="trimise"
              v-bind:orders="ordersSent"
              v-on:customer-click="answerCallForOrder"
              v-on:cancel-order="cancelOrder"
            />
          </b-col>

          <b-col cols="4">
            <OrdersListWidget
              title="confirmate"
              v-bind:orders="ordersConfirmed"
              v-on:customer-click="answerCallForOrder"
              v-on:remove-order="removeOrder"
            />
          </b-col>

          <b-col cols="4">
            <OrdersListWidget
              title="anulate"
              v-bind:orders="ordersCancelled"
              v-on:customer-click="answerCallForOrder"
              v-on:remove-order="removeOrder"
              v-on:resend-order="resendOrder"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="3" class="col-xxl-2">
        <CallsListWidget
          v-on:show-recent-orders="showRecentOrders"
        />
      </b-col>
    </b-row>

    <RecentOrdersDialog ref="recentOrdersDialog" />
    <ConfirmCallTransferDialog ref="confirmCallTransferDialog" />
    <ConfirmResendOrderDialog ref="confirmResendOrderDialog" />
  </div>
</template>

<script>
import delay from "delay";
import lodash from "lodash";
import moment from "moment";

import { mapActions, mapGetters, mapState } from "vuex";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";
import { mapOrder } from "@/core/models/order";
import { isNullOrWhiteSpace } from "@/core/utils";

import MapWidget from "@/view/content/widgets/dispatch/Map.vue";
import CallsListWidget from "@/view/content/widgets/dispatch/CallsList.vue";
import OrderFormWidget from "@/view/content/widgets/dispatch/OrderForm.vue";
import OrdersListWidget from "@/view/content/widgets/dispatch/OrdersList.vue";

import RecentOrdersDialog from "@/view/content/dialogs/dispatch/RecentOrders.vue";
import ConfirmCallTransferDialog from "@/view/content/dialogs/calls/ConfirmCallTransfer.vue";
import ConfirmResendOrderDialog from "@/view/content/dialogs/dispatch/ConfirmResendOrder.vue";

const orderCancelRemoveDelay = 2000;

let subscriptions;

export default {
  components: {
    MapWidget,
    CallsListWidget,
    OrderFormWidget,
    OrdersListWidget,

    RecentOrdersDialog,
    ConfirmCallTransferDialog,
    ConfirmResendOrderDialog,
  },

  data() {
    return {
      markedLocation: null,
      centeredLocation: null,
      highlightedLocation: null,

      orders: {},

      orderToResend: null,

      showAllOrders: true,

      settings: null,
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters("phone", ["activeCall", "heldCalls", "endedCalls"]),
    ...mapState  ("phone", ["calls"]),

    ordersSent() {
      const sent = Object.values(this.orders).filter(order => order.status === "sent");

      return lodash.orderBy(sent, "created");
    },

    ordersConfirmed() {
      const confirmed = Object.values(this.orders).filter(order => order.status === "confirmed");

      return lodash.orderBy(confirmed, "confirmed");
    },

    ordersCancelled() {
      const cancelled = Object.values(this.orders).filter(order => order.status === "cancelled");

      return lodash.orderBy(cancelled, "cancelled");
    },

    ordersAutoConfirm() {
      const finalized = Object.values(this.orders).filter(order =>
        order.status === "confirmed" || (order.status === "cancelled" && order.reason === "carNotFound"));

      const confirmable = finalized.filter(order => {
        if (order.multiple) {
          const count = finalized.filter(o => o.multiple && o.customer.phone === order.customer.phone).length;
          return count === order.multiple.count;
        } else {
          return true;
        }
      });

      return lodash.orderBy(confirmable, "created");
    },
  },

  watch: {
    activeCall(current, previous) {
      if (current && (!previous || previous.phone !== current.phone)) {
        this.$refs.orderForm.setCustomerByPhone(current.phone);
      }

      const ordersNoCustomer = Object.values(this.orders).find(o => !o.customer);
      if (ordersNoCustomer) {
        console.warn("[incomingCall] Found orders with no customer!", JSON.stringify(ordersNoCustomer));
      }

      if (previous) {
        const orders = Object.values(this.orders).filter(o => o.customer.phone === previous.phone);

        orders.forEach(async order => {
          this.$set(order, "activeCall", false);
        });
      }

      if (current) {
        const orders = Object.values(this.orders).filter(o => o.customer.phone === current.phone);

        orders.forEach(async order => {
          this.$set(order, "activeCall", true);
        });
      }
    },

    async heldCalls(calls) {
      if (calls.length > 0) {
        await delay(1000); // HACK: Linphone doesn't want to transfer calls immediately after placing them on hold.
        await this.autoConfirmOrders(this.ordersAutoConfirm);
      }
    },

    async endedCalls(calls) {
      const ordersNoCustomer = Object.values(this.orders).find(o => !o.customer);
      if (ordersNoCustomer) {
        console.warn("[endedCalls] Found orders with no customer!", JSON.stringify(ordersNoCustomer));
      }

      for (const call of calls) {
        this.endedCall(call);
      }
    },

    async ordersAutoConfirm(orders) {
      if (orders.length > 0) {
        await this.autoConfirmOrders(orders);
      }
    },
  },

  async created() {
    subscriptions = [
      api.connection.events.reconnected.subscribe(this.loadOrders),
      api.connection.events.reconnected.subscribe(this.loadSettings),

      api.drivers.events.mockLocations.subscribe(event => {
        const ts = moment(event.timestamp).format("dddd, D MMMM Y [ora] HH:mm:ss");

        let msg = `Șoferul <b>${event.name}</b> cu ind. <b>${event.carId}</b> a folosit o aplicație de tip <b>FakeGPS</b>.`;

        if (!isNullOrWhiteSpace(event.appId)) {
          msg += "<br/><br/>";

          if (!isNullOrWhiteSpace(event.appName)) {
            msg += `Nume app: <b>${event.appName}</b><br/>`;
          }

          msg += `ID app: <b>${event.appId}</b><br/>`;
        }

        notify.warning(`${msg}<br/>${ts}`, -1);
      }),

      api.orders.events.new.subscribe(event => {
        if (this.orders[event.id]) return;

        if (event.dispatch && event.dispatch.id) {
          if (event.dispatch.id !== this.currentUser.id) {
            if (!this.showAllOrders) return;
          }
        } else {
            if (!this.showAllOrders) return;
        }

        const newOrder = mapOrder(event);

        this.$set(this.orders, event.id, newOrder);
      }),

      api.orders.events.confirmed.subscribe(event => {
        const order = this.orders[event.id];

        if (order) {
          this.$set(order, "duration", event.duration);
          this.$set(order, "confirmed", event.confirmed);
          this.$set(order, "car", event.car);
          this.$set(order, "status", "confirmed");
        }
      }),

      api.orders.events.cancelled.subscribe(event => {
        const order = this.orders[event.id];

        if (order) {
          this.$set(order, "reason", event.reason);
          this.$set(order, "cancelled", event.cancelled);
          this.$set(order, "status", "cancelled");
        }
      }),

      api.orders.events.hidden.subscribe(async event => {
        const order = this.orders[event.id];

        if (order) {
          const calls = this.calls.filter(c => c.status !== "ended" && c.phone === order.customer.phone);

          if (calls.length === 0) {
            this.$set(order, "removing", true);

            await delay(orderCancelRemoveDelay);

            this.$set(order, "removing", false);
            this.$delete(this.orders, order.id);
          }
        }
      }),

      api.settings.events.changed.subscribe(settings => {
        this.settings = settings;
      })
    ];

    await this.loadSettings();
    await this.loadOrders();
  },

  destroyed() {
    subscriptions.forEach(sub => sub.unsubscribe());
  },

  methods: {
    ...mapActions(["overridePageLayoutConfig"]),
    ...mapActions("phone", ["answerCallByPhoneNumber", "endCallByPhoneNumber", "transferCall"]),

    async showAllOrdersChanged(value) {
      this.showAllOrders = value;

      await this.loadOrders();
    },



    async mapClicked(location) {
      if (!location) return;

      const address = await api.addresses.reverseGeocode(location.lat, location.lng);

      if (address) {
        this.$refs.orderForm.setAddress(address);
      } else {
        this.$refs.orderForm.clearAddress();
      }

      this.$refs.orderForm.setLocation(location);
      this.highlightedLocation = location;
    },

    mapClear() {
      this.markedLocation      = null;
      this.centeredLocation    = null;
      this.highlightedLocation = null;
      this.$refs.map.reset();
    },



    async addressChanged(address) {
      const location = await api.addresses.geocode(address);

      if (location) {
        this.markedLocation   = location;
        this.centeredLocation = location;
      }
    },



    async loadSettings() {
      this.settings = await api.settings.get();
    },

    async loadOrders() {
      const recentOrders = await api.orders.list(this.showAllOrders);
      const mappedOrders = recentOrders.map(mapOrder);

      this.orders = lodash.keyBy(mappedOrders, "id");
    },

    newOrder(order) {
      order.activeCall = this.activeCall && this.activeCall.phone === order.customer.phone;
      order.dispatch   = Object.freeze(this.currentUser);

      this.$set(this.orders, order.id, order);
    },

    async cancelOrder(order) {
      this.$set(order, "cancelling", true);

      await api.orders.cancel(order.id);

      this.$set(order, "cancelling", false);
      this.$set(order, "status", "cancelled");
      this.$set(order, "reason", "cancelledByDispatch");
    },

    async removeOrder(order) {
      this.$set(order, "removing", true);

      await api.orders.hide(order.id);

      this.$set(order, "removing", false);
      this.$delete(this.orders, order.id);

      await this.endCallByPhoneNumber(order.customer.phone);
    },

    async resendOrder(order) {
      const confirmed = await this.$refs.confirmResendOrderDialog.show(order);

      if (confirmed) {
        const request = {
          address: order.address,
          notes:   order.notes,

          customer: order.customer,

          cars: 1, // TODO: Always one?
        };

        const orderIds = await api.orders.new(request);

        const newOrder = {
          id: orderIds[0],

          created: moment().toISOString(),

          status: "sent",

          address: request.address,
          notes:   request.notes,

          customer: request.customer,
        };

        this.newOrder(newOrder);
      }
    },

    async showRecentOrders(call) {
      const recentOrders = await api.orders.recent(call.phone);
      const mappedOrders = recentOrders.map(mapOrder);

      const chosen = await this.$refs.recentOrdersDialog.show(call.phone, mappedOrders);

      if (chosen) {
        const customer = lodash.pick(chosen.customer, ["name", "phone"]);
        const location = lodash.pick(chosen.customer, ["lat", "lng"]);

        this.$refs.orderForm.setAddress(chosen.address);
        this.$refs.orderForm.setNotes(chosen.notes, true);

        if (customer.name && customer.phone) {
          this.$refs.orderForm.setCustomer(customer);
        }

        if (location.lat && location.lng) {
          this.$refs.orderForm.setLocation(location);

          this.centeredLocation    = location;
          this.highlightedLocation = location;
        }
      }
    },

    async answerCallForOrder(phone) {
      const result = await this.answerCallByPhoneNumber(phone);
      if (result.answered || result.error) return;

      const order     = Object.values(this.orders).find(o => o.customer.phone === phone);
      const confirmed = await this.$refs.confirmCallTransferDialog.show(order, result.dispatcher);

      if (confirmed) {
        await this.answerCallByPhoneNumber({ phone, confirm: result });
      }
    },

    async endedCall(call) {
      if (!call) {
        console.warn("[endedCall] Call is null or undefined!");
      }

      this.$refs.orderForm.clearCustomerByPhone(call.phone);

      const orders = Object.values(this.orders).filter(o => o.customer.phone === call.phone);

      orders.forEach(async order => {
        this.$set(order, "activeCall", false);

        if (order.status === "sent") {
          this.$set(order, "cancelling", true);
          await delay(orderCancelRemoveDelay);

          await this.cancelOrder(order);
        }

        this.$set(order, "removing", true);
        await delay(orderCancelRemoveDelay);

        this.removeOrder(order);
      });
    },

    async autoConfirmOrders(orders) {
      const autoConfirm = this.settings.autoConfirm;

      if (!autoConfirm.enabled) {
        return; // Auto confirm
      }

      if (autoConfirm.enabledFor.length > 0 && !autoConfirm.enabledFor.includes(this.currentUser.id)) {
        return;
      }

      for (const order of orders) {
        const call = this.calls.find(c => c.phone === order.customer.phone);

        if (!call || call.status !== "onHold") {
          return; // Only on-hold calls can be transferred for auto confirmation.
        }

        await this.transferCall({
          phone: order.customer.phone,
          target: autoConfirm.destination,
        });
      }
    },
  },
};
</script>

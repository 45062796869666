<template>
  <b-form
    id="order-form"
    autocomplete="off"
    novalidate
    v-on:submit.prevent="submit"
    v-on:reset.prevent="reset"
  >
    <div class="card card-custom card-stretch bg-light gutter-b">
      <div class="card-header">
        <h4 class="card-title">Comandă nouă</h4>

        <div class="card-toolbar">
          <b-form-checkbox v-model="showAllOrders" switch>
            Toate comenzile
          </b-form-checkbox>
        </div>
      </div>

      <div class="card-body">
        <b-form-group label="Client" label-size="lg" label-cols="3" label-class="font-weight-bold pt-0">
          <b-form-group label="Telefon" label-for="customer-phone" label-cols-xl="3">
            <b-form-input
              id="customer-phone"
              aria-describedby="customer-phone-feedback"
              type="tel"
              v-model="$v.customer.phone.$model"
              v-bind:state="state.customer.phone"
              v-bind:trim="true"
            />

            <b-form-invalid-feedback id="customer-phone-feedback">
              <span v-if="!$v.customer.phone.required">Numărul de telefon este obligatoriu.</span>
              <span v-if="!$v.customer.phone.phone">Numărul de telefon este incorect.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Nume" label-for="customer-name" label-cols-xl="3">
            <b-form-input
              id="customer-name"
              aria-describedby="customer-name-feedback"
              v-model="$v.customer.name.$model"
              v-bind:state="state.customer.name"
              v-bind:trim="true"
            />

            <b-form-invalid-feedback id="customer-name-feedback">
              <span v-if="!$v.customer.name.required">Numele clientului este obligatoriu.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Limbă" label-for="customer-language" label-cols-xl="3" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              plain
              id="customer-language"
              name="customer-language"
              v-model="customer.language"
              v-bind:aria-describedby="ariaDescribedby"
              v-bind:state="$v.customer.language.required"
              v-bind:class="{ 'is-invalid': !$v.customer.language.required }"
            >
              <b-form-radio required value="ro" v-bind:class="{ 'checked': customer.language === 'ro' }">
                <span class="svg-icon">
                  <inline-svg src="media/svg/flags/109-romania.svg" aria-label="Romănâ" />
                </span>
              </b-form-radio>

              <b-form-radio required value="en" v-bind:class="{ 'checked': customer.language === 'en' }">
                <span class="svg-icon">
                  <inline-svg src="media/svg/flags/012-uk.svg" aria-label="Engleză" />
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form-group>

        <b-form-group label="Adresă" label-size="lg" label-cols="3" label-class="font-weight-bold pt-0">
          <b-form-group label="Cartier" label-for="address-zone" label-cols-xl="3">
            <b-form-input
              id="address-zone"
              aria-describedby="address-zone-feedback"
              v-model="$v.address.zone.$model"
              v-bind:state="state.address.zone"
              v-bind:trim="true"
              v-on:input="resetEmptyAddressFields"
              v-on:change="addressChanged"
            />

            <b-form-invalid-feedback id="address-zone-feedback">
              <span v-if="!$v.address.zone.required">Cartierul este obligatoriu.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Stradă" label-for="address-street" label-cols-xl="3">
            <b-form-input
              id="address-street"
              aria-describedby="address-street-feedback"
              v-model="$v.address.street.$model"
              v-bind:state="state.address.street"
              v-bind:trim="true"
              v-on:input="resetEmptyAddressFields"
              v-on:change="addressChanged"
            />

            <b-form-invalid-feedback id="address-street-feedback">
              <span v-if="!$v.address.street.required">Strada este obligatorie.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Număr" label-for="address-street-no" label-cols-xl="3">
            <b-form-input
              id="address-street-no"
              aria-describedby="address-street-no-feedback"
              v-model="$v.address.streetNo.$model"
              v-bind:state="state.address.streetNo"
              v-bind:trim="true"
              v-on:input="resetEmptyAddressFields"
              v-on:change="addressChanged"
            />

            <b-form-invalid-feedback id="address-street-no-feedback">
              <span v-if="!$v.address.streetNo.required">Numărul este obligatoriu.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row class="row-inline">
            <b-col id="building-no-row" cols="6">
              <b-form-group label="Bloc" label-for="address-building-no" label-cols="5" label-cols-xl="6">
                <b-form-input
                  id="address-building-no"
                  aria-describedby="address-building-no-feedback"
                  v-model="$v.address.buildingNo.$model"
                  v-bind:state="state.address.buildingNo"
                  v-bind:trim="true"
                  v-on:input="resetEmptyAddressFields"
                  v-on:change="addressChanged"
                />

                <b-form-invalid-feedback id="address-building-no-feedback">
                  <span v-if="!$v.address.buildingNo.required">Blocul este obligatoriu.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col id="block-no-row" cols="6">
              <b-form-group label="Scară" label-for="address-block-no" label-cols="5">
                <b-form-input
                  id="address-block-no"
                  aria-describedby="address-block-no-feedback"
                  v-model="$v.address.blockNo.$model"
                  v-bind:state="state.address.blockNo"
                  v-bind:trim="true"
                  v-on:input="resetEmptyAddressFields"
                  v-on:change="addressChanged"
                />

                <b-form-invalid-feedback id="address-block-no-feedback">
                  Scara conține caractere nepermise.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Punct de interes" label-for="address-landmark" label-cols-xl="3">
            <b-form-input
              id="address-landmark"
              aria-describedby="address-landmark-feedback"
              v-model="$v.address.landmark.$model"
              v-bind:state="state.address.landmark"
              v-bind:trim="true"
              v-on:input="resetEmptyAddressFields"
              v-on:change="addressChanged"
            />

            <b-form-invalid-feedback id="address-landmark-feedback">
              <span v-if="!$v.address.landmark.required">Punctul de interes este obligatoriu.</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col cols-cols="10" offset-cols="2">
              <b-form-invalid-feedback v-if="addressRequired" id="address-invalid-feedback">
                Adresa este incompletă.
              </b-form-invalid-feedback>

              <b-form-invalid-feedback v-if="error" id="error-invalid-feedback">
                {{ error }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group label="Observații" label-size="lg" label-cols="3" label-class="font-weight-bold pt-0">
          <b-form-group label="Observații" label-cols-xl="3" label-for="notes" id="notes-group">
            <b-form-input id="notes" v-model="notes" v-bind:trim="true" />
          </b-form-group>

          <b-form-group label="Număr mașini" label-for="cars-requested" label-cols-xl="3" id="cars-requested-group">
            <b-form-input
              id="cars-requested"
              aria-describedby="cars-requested-feedback"
              type="number"
              v-model="$v.carsRequested.$model"
              v-bind:state="state.carsRequested"
              v-bind:min="1"
              v-bind:max="10"
              v-bind:number="true"
              v-bind:no-wheel="true"
            />

            <b-form-invalid-feedback id="cars-requested-feedback">
              <span v-if="!$v.carsRequested.minValue || !$v.carsRequested.required">
                Trebuie să solicitați cel puțin o mașină.
              </span>

              <span v-if="!$v.carsRequested.maxValue">
                Se pot solicita maxim {{ $v.carsRequested.$params.maxValue.max }} mașini la aceeași adresă.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col cols-cols="10" offset-cols="2">
              <b-form-invalid-feedback v-if="$v.location.$anyError" id="location-required-feedback">
                Este necesar să alegeți locația pe hartă.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form-group>
      </div>

      <div class="card-footer d-flex justify-content-between">
        <b-button type="reset" variant="danger">Șterge tot</b-button>
        <b-button type="button" variant="warning" v-on:click="resetAddress">Șterge adresa</b-button>
        <b-button type="submit" variant="primary">Trimite</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";
import moment from "moment";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { validationMixin } from "vuelidate";
import { helpers, minValue, maxValue, required, requiredIf } from "vuelidate/lib/validators";

import api, { AddressUnknownError } from "@/core/services/api";
import { isNullOrEmpty, formatAddress } from "@/core/utils";

const phone = helpers.regex("phone", /^(?:(0[23][0-9]{5})|(0[237][0-9]{8})|(\+[0-9]{8,15}))$/);

function mapValidationState(state) {
  if (state.$dirty) {
    if (state.$error) {
      return false;
    }

    if (!isNullOrEmpty(state.$model)) {
      return true;
    }
  }

  return null;
}

function zoneRequired(address) {
  const hasStreet     = !isNullOrEmpty(address.street);
  const hasStreetNo   = !isNullOrEmpty(address.streetNo);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasStreet) {
    if (hasStreetNo || hasBuildingNo) {
      return false;
    }
  }

  return hasBuildingNo;
}

function streetRequired(address) {
  const hasZone       = !isNullOrEmpty(address.zone);
  const hasStreetNo   = !isNullOrEmpty(address.streetNo);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasZone && hasBuildingNo) {
    return false;
  }

  return hasStreetNo || hasBuildingNo;
}

function streetNoRequired(address) {
  const hasZone       = !isNullOrEmpty(address.zone);
  const hasStreet     = !isNullOrEmpty(address.street);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasBuildingNo) {
    if (hasZone || hasStreet) {
      return false;
    }
  }

  return hasStreet;
}

function buildingNoRequired(address) {
  const hasZone     = !isNullOrEmpty(address.zone);
  const hasStreet   = !isNullOrEmpty(address.street);
  const hasStreetNo = !isNullOrEmpty(address.streetNo);
  const hasLandmark = !isNullOrEmpty(address.landmark);
  const hasNotes    = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasStreet && hasStreetNo) {
    return false;
  }

  return hasZone || hasStreet;
}

function landmarkRequired(address) {
  return false; // TODO
}

function autocompleteDebounce(handler) {
  return lodash.debounce((input, callback) => handler(input).then(callback), 100);
}

function autocompleteHandle(results, element) {
  if (element.is(":focus")) {
    return results;
  } else {
    return [];
  }
}

function autocompleteHighlight(haystack, needle) {
  if (needle.length === 0) return haystack;

  let startIndex = haystack.toLowerCase().indexOf(needle.toLowerCase());

  while (startIndex !== -1) {
    const endIndex = startIndex + needle.length;

    const before = haystack.slice(0, startIndex);
    const match  = haystack.slice(startIndex, endIndex);
    const after  = haystack.slice(endIndex);

    haystack   = `${before}<b>${match}</b>${after}`;
    startIndex = haystack.toLowerCase().indexOf(needle.toLowerCase(), endIndex + '<b></b>'.length);
  }

  return haystack;
}

export default {
  mixins: [validationMixin],

  data() {
    return {
      error: null,

      customer: {
        name:  "",
        phone: "",
        language: "ro",
      },

      address: {
        zone: "",
        street: "",
        streetNo: "",
        buildingNo: "",
        blockNo: "",
        landmark: "",
      },

      location: null,

      notes: "",
      carsRequested: 1,

      showAllOrders: true,
    };
  },

  validations: {
    customer: {
      name: {
        required,
      },

      phone: {
        phone,
        required,
      },

      language: {
        required,
      },
    },

    address: {
      zone: {
        required: requiredIf(zoneRequired),
      },

      street: {
        required: requiredIf(streetRequired),
      },

      streetNo: {
        required: requiredIf(streetNoRequired),
      },

      buildingNo: {
        required: requiredIf(buildingNoRequired),
      },

      blockNo: {
        // no validation
      },

      landmark: {
        required: requiredIf(landmarkRequired),
      },
    },

    location: {
      required: requiredIf("locationRequired"),
    },

    carsRequested: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(10),
    },
  },

  computed: {
    formFilled() {
      return this.$v.$dirty;
    },

    // Separate handling of empty fields is necessary because all validation
    // is done using requiredIf checking the presence of another field.
    // There is no address field that is required in all situations.
    addressEmpty() {
      const requiredAddressFields = [
        this.address.zone,
        this.address.street,
        this.address.streetNo,
        this.address.buildingNo,
        this.address.landmark
      ];

      return lodash.every(requiredAddressFields, isNullOrEmpty);
    },

    addressRequired() {
      return this.formFilled
          && this.addressEmpty
          && (isNullOrEmpty(this.notes) || this.$v.location.$anyError);
    },

    locationRequired() {
      return this.formFilled
          && this.addressEmpty
          && !isNullOrEmpty(this.notes)
          && !this.location;
    },

    state() {
      return {
        customer: {
          name:  mapValidationState(this.$v.customer.name),
          phone: mapValidationState(this.$v.customer.phone),
        },

        address: {
          zone:       mapValidationState(this.$v.address.zone),
          street:     mapValidationState(this.$v.address.street),
          streetNo:   mapValidationState(this.$v.address.streetNo),
          buildingNo: mapValidationState(this.$v.address.buildingNo),
          blockNo:    mapValidationState(this.$v.address.blockNo),
          landmark:   mapValidationState(this.$v.address.landmark),
        },

        location: mapValidationState(this.$v.location),

        carsRequested: mapValidationState(this.$v.carsRequested),
      };
    },
  },

  watch: {
    "customer.phone": async function(phone) {
      if (!phone) return;
      if (this.$v.customer.phone.$anyError) return;

      await this.updateCustomerName(phone);
    },

    showAllOrders(value) {
      this.$emit("show-all-orders", value);
    },
  },

  created() {
    this.addressChanged = lodash.debounce(() => {
      this.$emit("address-changed", { ...this.address });
    }, 250);

    this.updateCustomerName = AwesomeDebouncePromise(async phone => {
        const customer = await api.customers.find(phone);

        if (customer) {
          this.setCustomer(customer);
        }
    });
  },

  mounted() {
    jQuery("#address-zone").autoComplete({
      resolver: "custom",
      noResultsText: "Niciun rezultat",

      formatResult: result => {
        const highlighted = autocompleteHighlight(result, jQuery("#address-zone").val());

        return {
          text: result,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchZone(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-zone").on("autocomplete.select", (_, zone) => {
      this.address.zone = zone;
      this.addressChanged();
    });



    jQuery("#address-street").autoComplete({
      resolver: "custom",
      noResultsText: "Niciun rezultat",

      formatResult: result => {
        const highlighted = autocompleteHighlight(result, jQuery("#address-street").val());

        return {
          text: result,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchStreet(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-street").on("autocomplete.select", (_, street) => {
      this.address.street = street;
      this.addressChanged();
    });



    jQuery("#address-building-no").autoComplete({
      resolver: "custom",
      minLength: 1,
      noResultsText: "Niciun rezultat",

      formatResult: address => {
        const formatted   = formatAddress(address, ["buildingNo"]);
        const highlighted = autocompleteHighlight(address.buildingNo, jQuery("#address-building-no").val());
        const display     = formatted.replace(`Bl. ${address.buildingNo}`, `Bl. ${highlighted}`);

        return {
          text: address.buildingNo,
          html: display,
        };
      },

      events: {
        search: autocompleteDebounce(input =>
          api.addresses.searchBuildingNo(input, this.address.zone, this.address.street)
        ),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-building-no").on("autocomplete.select", (event, address) => {
      this.address.buildingNo = address.buildingNo;

      if (!isNullOrEmpty(address.zone)) {
        this.address.zone = address.zone;
      }

      if (!isNullOrEmpty(address.street)) {
        this.address.street = address.street;
      }

      if (address.lat && address.lng) {
        this.location = {
          lat: address.lat,
          lng: address.lng,
        };
      }

      this.addressChanged();
    });



    jQuery("#address-landmark").autoComplete({
      resolver: "custom",
      minLength: 2,
      noResultsText: "Niciun rezultat",

      formatResult: address => {
        const formatted   = formatAddress(address, ["landmark"]);
        const highlighted = autocompleteHighlight(address.landmark, jQuery("#address-landmark").val());
        const display     = formatted.replace(address.landmark, highlighted);

        return {
          text: address.landmark,
          html: display,
        };
      },

      events: {
        search: autocompleteDebounce(input =>
          api.addresses.searchLandmark(input, this.address.zone, this.address.street)
        ),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-landmark").on("autocomplete.select", (event, address) => {
      this.address.landmark = address.landmark;

      if (!isNullOrEmpty(address.zone)) {
        this.address.zone = address.zone;
      }

      if (!isNullOrEmpty(address.street)) {
        this.address.street = address.street;
      }

      if (address.lat && address.lng) {
        this.location = {
          lat: address.lat,
          lng: address.lng,
        };
      }

      this.addressChanged();
    });



    jQuery("#order-form input").keydown(event => {
      const target    = jQuery(event.target);
      const focusable = jQuery("#order-form")
        .find("input, select, textarea, [contenteditable=true]")
        .filter(":visible");

      // Index of the next/previous item (depending on [Shift] keypress).
      const next = focusable.index(target) + (event.shiftKey ? -1 : 1);

      // [Enter]
      if (event.which === 13) {
        // Skip enter-as-tab handling for textarea or content-editable elements.
        if (target.is("textarea, [contenteditable=true]")) return;

        if (next >= 0 && next < focusable.length) {
          // Focus the next/previous element.
          focusable.eq(next).focus();
        }

        event.stopPropagation();
        event.preventDefault();
      }

      // [Tab]
      if (event.which === 9) {
        // Stop tabbing at the first & last elements.
        if (next < 0 || next >= focusable.length) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    });
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    clearCustomer() {
      this.customer.name     = "";
      this.customer.phone    = "";
      this.customer.language = "ro";
    },

    clearCustomerByPhone(phone) {
      if (phone && this.customer.phone === phone) {
        this.clearCustomer();
      }
    },

    setCustomer(customer) {
      this.clearCustomer();

      if (customer) {
        this.customer.name     = customer.name     || "";
        this.customer.phone    = customer.phone    || "";
        this.customer.language = customer.language || "ro";
      }
    },

    async setCustomerByPhone(phone) {
      this.clearCustomer();

      if (phone) {
        this.customer.phone = phone;

        const customer = await api.customers.find(phone);
        if (customer) {
          this.customer.name     = customer.name;
          this.customer.language = customer.language || "ro";
        }
      }
    },

    clearAddress() {
      Object.keys(this.address).forEach(key => {
        this.$set(this.address, key, "");
      });
    },

    setAddress(address) {
      this.clearAddress();

      if (address) {
        this.address = Object.assign({}, this.address, address);
      }
    },

    setLocation(location) {
      this.location = location;
    },

    setNotes(notes, overwrite) {
      if (overwrite || isNullOrEmpty(this.notes)) {
        this.notes = notes || "";
      }
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    resetEmptyAddressFields() {
      if (isNullOrEmpty(this.address.zone)) {
        this.$v.address.zone.$reset();
      }

      if (isNullOrEmpty(this.address.street)) {
        this.$v.address.street.$reset();
      }

      if (isNullOrEmpty(this.address.streetNo)) {
        this.$v.address.streetNo.$reset();
      }

      if (isNullOrEmpty(this.address.buildingNo)) {
        this.$v.address.buildingNo.$reset();
      }

      if (isNullOrEmpty(this.address.landmark)) {
        this.$v.address.landmark.$reset();
      }
    },

    async submit() {
      this.$v.$touch();

      if (this.$v.customer.$anyError) return;
      if (this.$v.address.$anyError) return;
      if (this.$v.location.$anyError) return;
      if (this.addressRequired) return;

      const request = {
        address: { ...this.address },
        customer: {
          ...this.customer,
          ...this.location,
        },
        notes: this.notes,
        cars: this.carsRequested,
      };

      try {
        const orderIds = await api.orders.new(request);

        this.reset();
        this.notify(request, orderIds);
      } catch (error) {
        if (error instanceof AddressUnknownError) {
          this.error = "Adresa nu este recunoscută."
        } else {
          throw error;
        }
      }
    },

    reset() {
      this.clearCustomer();
      this.resetAddress();
    },

    resetAddress() {
      this.location = null;

      Object.keys(this.address).forEach(key => {
        this.$set(this.address, key, "");
      });

      this.notes = "";

      this.carsRequested = 1;

      this.$v.$reset();
      this.$emit("reset");
    },

    notify(request, orderIds) {
      orderIds.forEach((orderId, index) => {
        const order = {
          id: orderId,

          created: moment().toISOString(),
          status: "sent",

          address: request.address,
          notes:   request.notes,

          customer: request.customer,
        };

        if (orderIds.length > 1) {
          order.multiple = {
            index: index + 1,
            count: orderIds.length,
          };
        }

        this.$emit("new-order", order);
      });
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/_mixins.scss";

#order-form {
  .card-header {
    min-height: 50px;
  }

  .card-body {
    padding: 1rem 2.25rem;

    .form-group {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }

      &.form-row {
        margin-bottom: 0.5rem;

        @include media-breakpoint-down(lg) {
          label {
            padding-bottom: 0;
          }

          &:first-child {
            label {
              padding-top: 0;
            }
          }
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 1rem;
        }
      }

      .form-row {
        legend {
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .row-inline {
        @include media-breakpoint-down(lg) {
          margin-top: 1.25rem;

          .form-group {
            &.form-row {
              label {
                padding-top: calc(0.65rem + 1px);
              }
            }
          }
        }
      }
    }
  }

  .card-footer {
    padding: 1rem 2.25rem;
  }

  .bootstrap-autocomplete {
    padding: 0;
    width: auto !important;
    border: $input-border-width solid $input-border-color;

    .dropdown-item {
      display: block;
    }
  }
}

#address-invalid-feedback, #error-invalid-feedback {
  display: block;
  margin-bottom: 1rem;
}

#location-required-feedback {
  display: block;
}

#building-no-row {
  padding-right: 0.25rem;
}

#building-no-row, #block-no-row {
  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-down(xl) {
    input.is-valid {
      padding-right: 0;
      background-image: none;
    }
  }
}

#notes-group {
  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

#cars-requested-group {
  label {
    @include media-breakpoint-down(xl)
    {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  #customer-language {
    margin-top: 0.2rem;

    input[type="radio"] {
      display: none;
    }

    .form-check {
      &.checked {
        svg {
          outline: 1.25rem solid rgba(0, 200, 0, 0.5);
          outline-offset: -1.25rem;
        }
      }
    }

    .svg-icon {
      svg {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 0.7rem;
      }
    }
  }
}
</style>

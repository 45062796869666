<template>
  <div class="card card-custom card-stretch">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder text-dark">Comenzi {{ title }}</h3>
    </div>

    <div class="card-body">
      <OrderWidget
        v-for="order in orders"
        timestamp="created"
        v-bind:key="order.id"
        v-bind:order="order"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

export default {
  components: {
    OrderWidget,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    orders: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    border: 0;
    min-height: 50px;
  }

  .card-body {
    padding-top: 0;
  }
}
</style>

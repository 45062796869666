import { Control } from "ol/control";

function ButtonControl(options) {
  if (!options) throw new Error("Missing options.")
  if (typeof(options) !== "object") throw new Error("Options is not an object.");

  if (!options.callback) throw new Error("Missing callback.")
  if (typeof(options.callback) !== "function") throw new Error("Callback is not a function");

  if (!options.content) throw new Error("Missing content");
  if (typeof(options.content) !== "string") throw new Error("Content is not a string");

  if (!options.classes) throw new Error("Missing classes");
  if (typeof(options.classes) !== "string") throw new Error("Classes is not a string");

  const html = `<div class="${options.classes} ol-unselectable ol-control">
  <button>${options.content}</button>
</div>
`;

  const $element = $(html);

  $element.find("button").on("click", () => options.callback());
  Control.call(this, { element: $element[0] });
}

ButtonControl.__proto__ = Control;
ButtonControl.prototype = Object.create( Control && Control.prototype );

ButtonControl.prototype.constructor = ButtonControl;

export default ButtonControl;
<template>
  <div id="calls-list">
    <div class="card card-custom card-stretch bg-light">
      <div class="card-header">
        <h4 class="card-title">Apeluri</h4>

        <div class="card-toolbar">
          <i class="fas fa-phone text-info mr-3" />

          <b-form-select
            class="mr-2"
            size="sm"
            v-model="selectedSoftphone"
            v-bind:disabled="!disconnected"
          >
            <b-form-select-option value="bria">Bria</b-form-select-option>
            <b-form-select-option value="linphone">Linphone</b-form-select-option>
          </b-form-select>

          <b-button
            class="btn-success"
            size="sm"
            v-if="disconnected || connecting"
            v-bind:disabled="connecting"
            v-on:click="connect"
          >
            Conectare
          </b-button>

          <b-button
            class="btn-danger"
            size="sm"
            v-else
            v-bind:disabled="disconnecting"
            v-on:click="disconnect"
          >
            Deconectare
          </b-button>
        </div>
      </div>

      <div class="card-body">
        <b-alert variant="info" v-bind:show="!connected">Nu v-ați conectat la <span class="capitalize">{{ softphone }}</span>.</b-alert>
        <b-alert variant="success" v-bind:show="connected && !anyCalls">Niciun apel în curs.</b-alert>

        <b-list-group v-if="anyCalls">
          <b-list-group-item
            class="call d-flex align-items-center justify-content-between"
            v-for="call in calls"
            v-bind:key="call.id"
            v-bind:variant="callVariants[call.status]"
            v-bind:class="[ `call-${call.status}` ]"
          >
            <h4
              class="list-group-item-heading"
              v-b-tooltip.hover.left="'Click pentru istoric comenzi.'"
              v-on:click="showRecentOrders(call)"
            >
              {{ call.phone | phone }}
            </h4>

            <b-button-group v-if="call.status === 'incoming'" size="sm">
              <b-button variant="success" v-on:click="answerCall(call)">Răspunde</b-button>
              <b-button variant="danger" v-on:click="endCall(call)">Respinge</b-button>
            </b-button-group>

            <b-button-group v-if="call.status === 'active'" size="sm">
              <b-button variant="warning" v-on:click="holdCall(call)">Așteptare</b-button>
              <b-button variant="danger" v-on:click="endCall(call)">Închide</b-button>
            </b-button-group>

            <b-button-group v-if="call.status === 'onHold'" size="sm">
              <b-button variant="success" v-on:click="resumeCall(call)">Răspunde</b-button>
              <b-button variant="danger" v-on:click="endCall(call)">Închide</b-button>
            </b-button-group>

            <b-button
              size="sm"
              variant="danger"
              class="btn-icon btn-circle btn-remove"
              v-if="call.status === 'ended'"
              v-on:click="removeEndedCall(call)"
            >
              <b-spinner />
              <i class="fas fa-times-circle" />
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { formatPhoneNumber } from "@/core/utils";

export default {
  filters: {
    phone: formatPhoneNumber,
  },

  data() {
    return {
      callVariants: Object.freeze({
        incoming: "primary",
        active:   "success",
        onHold:   "warning",
        ended:    "danger",
      }),

      selectedSoftphone: this.$store.getters["phone/softphone"],
    };
  },

  computed: {
    ...mapState("phone", ["calls"]),

    ...mapGetters("phone", [
      "connected",
      "connecting",
      "disconnected",
      "disconnecting",

      "anyCalls",
      "softphone"
    ]),
  },

  watch: {
    selectedSoftphone(value) {
      switch (value) {
        case "bria":
          this.useBria();
          break;

        case "linphone":
          this.useLinphone();
          break;
      }
    },
  },

  methods: {
    ...mapMutations("phone", [
      "useBria",
      "useLinphone"
    ]),

    ...mapActions("phone", [
      "connect",
      "disconnect",

      "answerCall",
      "holdCall",
      "resumeCall",
      "endCall",
      "removeEndedCall"
    ]),

    showRecentOrders(call) {
      this.$emit("show-recent-orders", call);
    },
  },
};
</script>

<style lang="scss">
#calls-list {
  height: 100%;

  .call {
    &.call-incoming {
      color: white;
      background: $primary;

      .list-group-item-heading {
        &:hover {
          color: $warning;
        }
      }
    }

    .list-group-item-heading {
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
        color: $info;
      }
    }

    .btn-remove {
      width: 2rem;
      height: 2rem;
      border: none;
      background: none;
      position: relative;
      margin: 0.2rem 0;

      i {
        border-radius: 50%;
        background: $danger;
        margin-left: 1px;
      }

      .spinner-border {
        color: $danger;
        position: absolute;
        border-width: 0.35rem;
      }

      &:hover {
        i {
          background: $danger-hover;
        }

        .spinner-border {
          color: $danger-hover;
        }
      }
    }
  }

  .alert {
    .capitalize {
      text-transform: capitalize;
    }
  }

  .card {
    .card-header {
      min-height: 50px;

      .card-toolbar {
        select {
          width: auto;
        }

        button {
          i {
            font-size: 1rem;
          }
        }
      }
    }

    .card-body {
      padding: 1rem 2.25rem;
    }

    .card-footer {
      padding: 1rem 2.25rem;
    }
  }
}
</style>
